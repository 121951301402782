import React, {useState} from "react";
import {StaticImage} from "gatsby-plugin-image";
import {isValidEmail} from "../utils/validation";
import Modal from "./modal";

const NewsletterCta = () => {

  const [email, setEmail] = useState('');
  const [modal, setModal] = useState({
    hidden: true
  });

  const submitEmail = (event) => {
    fetch(`${process.env.GATSBY_API_ENDPOINT}/public/newsletter/email`, {
      method: "PUT",
      headers: { "Content-Type": "application/json", "X-API-VERSION": "1" },
      body: JSON.stringify({
        email
      })
    })
      .then(response => {
        if (response.ok) {
          return true;
        } else {
          throw response.statusText;
        }
      })
      .then(() => {
        setModal({
          hidden: false,
          title: "Thank you for subscribing 🎉️",
          message: "You will be the first to know about new releases. Stay tuned."
        });
        setEmail('');
      })
      .catch(err => setModal({
        hidden: false,
        title: 'Something went wrong 🙈',
        message: "Please try again."
      }))
    event.preventDefault();
  }

  const handleDismiss = () => {
    setModal({
      ...modal,
      hidden: true
    });
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  return (
    <>
      <section className="bg-banner bg-fixed">
        <div className="container pt-12 pb-20 text-center text-white">
          <StaticImage className="w-20 transform rotate-180" src="../images/pencil.svg" alt="pencil" placeholder="blurred"/>
          <h2 className="mb-4">Get Product Updates</h2>
          <p className="mb-8">
            Be the first to know when we release new features!<br/>
            (Unsubscribe at any time)
          </p>
          <form onSubmit={submitEmail} className="flex flex-col sm:flex-row max-w-lg mx-auto">
            <input onChange={handleEmailChange}
                   type="email"
                   value={email}
                   className="rounded-full sm:rounded-r-none sm:rounded-l-full px-8 py-3 text-lg text-black flex-auto"
                   name="email"
                   placeholder="Your Email address here"
                   required/>
            <button type="submit"
                    disabled={!isValidEmail(email)}
                    className="sm:rounded-l-none sm:rounded-r-full mt-3 sm:mt-0 text-lg">
              Subscribe
            </button>
          </form>
        </div>
      </section>

      <Modal hidden={modal.hidden} onDismiss={handleDismiss}>
        <h1 className="text-4xl">{modal.title}</h1>
        <p className="text-xl">{modal.message}</p>
      </Modal>
    </>
  )
}

export default NewsletterCta
