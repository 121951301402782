/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header";
import Footer from "./footer";
import SEO from "./seo";
import CookieConsent from "./cookie-consent";
import {useCookies} from "react-cookie";
import NewsletterCta from "./newsletter-cta";

const Layout = ({ children, title }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [cookies, setCookie,] = useCookies();
  const analyticsConsent = cookies[process.env.GATSBY_ANALYTICS_CONSENT_COOKIE];

  const [cookieBannerHidden, setCookieBannerHidden] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setCookieBannerHidden(analyticsConsent !== undefined);
    }, 500);
  }, [setCookieBannerHidden, analyticsConsent]);

  const handleAccept = () => {
    // store cookie consent (accept)
    setCookieBannerHidden(true);
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    setCookie(process.env.GATSBY_ANALYTICS_CONSENT_COOKIE, true, {
      expires: oneYearFromNow
    });

    // enable Google Analytics and send page_view event
    window[`ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`] = false;
    window.gtag('event', 'page_view', {
      page_title: title + ' | ' + data.site.siteMetadata.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
      send_to: process.env.GATSBY_GA_TRACKING_ID
    })
  }

  const handleDecline = () => {
    // store cookie consent (decline)
    setCookieBannerHidden(true);
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    setCookie(process.env.GATSBY_ANALYTICS_CONSENT_COOKIE, false, {
      expires: oneYearFromNow
    });

    // disable Google Analytics (should already be disabled, just making sure :P)
    window[`ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`] = true;
  }

  return (
    <>
      <SEO title={title}/>
      <div className="min-h-screen min-w-370 relative">
        <Header siteTitle={data.site.siteMetadata.title}/>
        <main>
          {children}
        </main>
        <NewsletterCta/>
        <Footer/>
      </div>
      <CookieConsent onAccept={handleAccept} onDecline={handleDecline} hidden={cookieBannerHidden}/>
    </>
  )
}

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

Layout.propDefaults = {
}

export default Layout
