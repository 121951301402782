import React from "react";

const AppStore = () => {
  return (
    <div className="flex flex-row">
      <div className="w-40 xs:w-48">
        <a href="https://itunes.apple.com/us/app/scribblex/id1434091800?mt=8">
          <img className="" alt="Download on the App Store" style={{margin: '6%', width: '88%'}}
               src="https://linkmaker.itunes.apple.com/en-gb/badge-lrg.svg?releaseDate=2019-05-14&kind=iossoftware&bubble=ios_apps"/>
        </a>
      </div>
      <div className="w-40 xs:w-48">
        <a
          href='https://play.google.com/store/apps/details?id=com.scribblex.scribblex&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
          <img className="" alt='Get it on Google Play'
               src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'/>
        </a>
      </div>
    </div>
  )
}

export default AppStore
