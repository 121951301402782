const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  purge: ["./src/**/*.{js,jsx,ts,tsx}"],
  darkMode: false, // or 'media' or 'class'
  theme: {
    screens: {
      'xs': '475px',
      ...defaultTheme.screens,
    },
    fontFamily: {
      'sans': ['Rubik'],
      'body': ['Rubik'],
    },
    container: {
      center: true,
      padding: '2rem',
    },
    extend: {
      colors: {
        'primary': {
          'light': '#9be3ea',
          'DEFAULT': '#78C5CC',
          'dark': '#6ba4ab'
        },
        'background': '#F2F6F7',
      },
      fontFamily: {
        'brand': ['"Chelsea Market"']
      },
      height: theme => ({
        "screen-50": "50vh",
        "screen-66": "66vh",
        "screen-75": "75vh",
      }),
      minWidth: {
        '370': '370px'
      },
      borderRadius: {
        "50": '50%'
      },
      backgroundImage: theme => ({
        'banner': "linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8) ), url('../images/banner.png')",
      }),
      brightness: {
        25: '.4',
      },
    },
  },
  variants: {
    extend: {
      pointerEvents: ['disabled'],
      opacity: ['disabled'],
      grayscale: ['hover', 'focus'],
      visibility: ['hover', 'focus'],
    }
  },
  plugins: [],
}
