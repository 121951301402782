import React from "react"
import {Link} from "gatsby"
import AppStore from "./appstore";
import {IoLogoFacebook, IoLogoInstagram, IoLogoTwitter, IoLogoYoutube} from "react-icons/io5";

const Footer = () => {

  const nav = [
    { label: 'Home', url: '/' },
    { label: 'Gallery', url: '/gallery' },
    { label: 'About', url: '/about' },
    { label: 'Contact', url: '/contact' },
  ]

  const social = [
    { icon: <IoLogoFacebook/>, url: 'https://www.facebook.com/ScribbleXApp/' },
    { icon: <IoLogoInstagram/>, url: 'https://www.instagram.com/scribblex_app/' },
    { icon: <IoLogoTwitter/>, url: 'https://twitter.com/scribblex_app' },
    { icon: <IoLogoYoutube/>, url: 'https://www.youtube.com/channel/UC6-dEZ4W-mbopdVkd0P5gPg' },
  ]

  return (
    <footer className="bg-primary text-white">
      <div className="container pt-12 pb-10 text-center flex flex-col items-center max-w-xl">
        <Link className="no-underline mb-4" to="/">
          <h1 className="text-5xl">ScribbleX</h1>
        </Link>
        <AppStore/>
        <nav className="mt-4 flex justify-between text-xl w-full" role="navigation">
          {nav.map((navItem, index) => (
            <Link key={`nav-item-${index}`}
                  to={navItem.url}
                  className="no-underline font-normal">
              {navItem.label}
            </Link>
          ))}
        </nav>
        <div className="flex my-6">
          {social.map((socialItem, index) => (
            <a key={`social-${index}`} className="text-2xl mx-2" href={socialItem.url} target="_blank" rel="noreferrer">
              {socialItem.icon}
            </a>
          ))}
        </div>
        <p className="mb-2">
          Made with ❤ in Berlin<br/>
        </p>
        <p className="text-sm">
          Copyright &copy; {new Date().getFullYear()} ScribbleX | <Link to="/privacy">Privacy</Link> | <Link to="/terms">Terms</Link><br/>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
}

Footer.defaultProps = {
}

export default Footer
