import React, {useEffect, useState} from "react";
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

const NavItem = ({ label, url }) => {

  const [isHovered, setHovered] = useState(false);
  const [isActive, setActive] = useState(true);

  useEffect(() => {
    setActive(window.location.pathname === url);
  }, [url, setActive]);

  return (
    <Link to={url}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className="no-underline flex justify-center">
      <div className="relative text-center sm:text-left font-normal">
        {label}
        {(isActive || isHovered) &&
        <div className="absolute ml-4 sm:ml-0 top-1/2 left-full sm:top-full sm:left-0 transform -translate-y-1/2 sm:transform-none max-h-2 w-12 sm:w-full sm:max-w-full">
          <StaticImage className={`pencil-white h-full w-full`}
                       src="../images/pencil.png"
                       alt="active menu pencil"
                       objectFit="fill"
                       placeholder="blurred"/>
        </div>}
      </div>
    </Link>
  )
}

export default NavItem
